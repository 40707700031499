// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---node-modules-gatsby-plugin-offline-app-shell-js": () => import("./../node_modules/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---node-modules-gatsby-plugin-offline-app-shell-js" */),
  "component---src-components-blog-template-js": () => import("./../src/components/blogTemplate.js" /* webpackChunkName: "component---src-components-blog-template-js" */),
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-blog-js": () => import("./../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-contact-us-js": () => import("./../src/pages/contact-us.js" /* webpackChunkName: "component---src-pages-contact-us-js" */),
  "component---src-pages-contact-js": () => import("./../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-features-js": () => import("./../src/pages/features.js" /* webpackChunkName: "component---src-pages-features-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-products-js": () => import("./../src/pages/products.js" /* webpackChunkName: "component---src-pages-products-js" */),
  "component---src-pages-products-felis-d-1-js": () => import("./../src/pages/products/felis-d1.js" /* webpackChunkName: "component---src-pages-products-felis-d-1-js" */),
  "component---src-pages-products-felis-d-2-js": () => import("./../src/pages/products/felis-d2.js" /* webpackChunkName: "component---src-pages-products-felis-d-2-js" */),
  "component---src-pages-products-felis-d-3-js": () => import("./../src/pages/products/felis-d3.js" /* webpackChunkName: "component---src-pages-products-felis-d-3-js" */),
  "component---src-pages-products-felis-w-1-js": () => import("./../src/pages/products/felis-w1.js" /* webpackChunkName: "component---src-pages-products-felis-w-1-js" */),
  "component---src-pages-products-felis-w-2-js": () => import("./../src/pages/products/felis-w2.js" /* webpackChunkName: "component---src-pages-products-felis-w-2-js" */),
  "component---src-pages-products-felis-w-3-js": () => import("./../src/pages/products/felis-w3.js" /* webpackChunkName: "component---src-pages-products-felis-w-3-js" */),
  "component---src-pages-products-felis-w-4-js": () => import("./../src/pages/products/felis-w4.js" /* webpackChunkName: "component---src-pages-products-felis-w-4-js" */),
  "component---src-pages-products-smart-box-js": () => import("./../src/pages/products/smart-box.js" /* webpackChunkName: "component---src-pages-products-smart-box-js" */),
  "component---src-pages-products-smart-panel-js": () => import("./../src/pages/products/smart-panel.js" /* webpackChunkName: "component---src-pages-products-smart-panel-js" */),
  "component---src-pages-solutions-js": () => import("./../src/pages/solutions.js" /* webpackChunkName: "component---src-pages-solutions-js" */),
  "component---src-pages-solutions-laundry-management-system-js": () => import("./../src/pages/solutions/laundry-management-system.js" /* webpackChunkName: "component---src-pages-solutions-laundry-management-system-js" */),
  "component---src-pages-solutions-smart-laundromat-control-js": () => import("./../src/pages/solutions/smart-laundromat-control.js" /* webpackChunkName: "component---src-pages-solutions-smart-laundromat-control-js" */),
  "component---src-pages-success-js": () => import("./../src/pages/success.js" /* webpackChunkName: "component---src-pages-success-js" */)
}

